import TypeActions from '../TypeActions';

const initialState = {
  listLicense: [],
  licenseById: {},
  isGetting: false,
  isCreating: false,
  isEditing: false,
  isEditing: false,
  isReseting: false,
  isDeleting: false,
  dataCreate: [],
  error: ''
};

export const licenseReducer = (state = initialState, action) => {
  switch (action.type) {
    //!Get list license
    case TypeActions.GET_LIST_LICENSE_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_LIST_LICENSE_SUCCESS:
      return {
        ...state,
        listLicense: action.data,
        isGetting: false
      };
    case TypeActions.GET_LIST_LICENSE_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Get license
    case TypeActions.GET_LICENSE_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_LICENSE_SUCCESS:
      return {
        ...state,
        licenseById: action.data,
        isGetting: false
      };
    case TypeActions.GET_LICENSE_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Create license
    case TypeActions.CREATE_LICENSE_REQUEST:
      return {
        ...state,
        isCreating: true
      };
    case TypeActions.CREATE_LICENSE_SUCCESS:
      return {
        ...state,
        dataCreate: action.data,
        isCreating: false
      };
    case TypeActions.CREATE_LICENSE_FAILED:
      return {
        ...state,
        error: action.error,
        isCreating: false
      };
    //!Edit license
    case TypeActions.EDIT_LICENSE_REQUEST:
      return {
        ...state,
        isEditing: true
      };
    case TypeActions.EDIT_LICENSE_SUCCESS:
      return {
        ...state,
        isEditing: false
      };
    case TypeActions.EDIT_LICENSE_FAILED:
      return {
        ...state,
        error: action.error,
        isEditing: false
      };
    // reset
    case TypeActions.RESET_LICENSE_REQUEST:
      return {
        ...state,
        isReseting: true
      };
    case TypeActions.RESET_LICENSE_SUCCESS:
      return {
        ...state,
        isReseting: false
      };
    case TypeActions.RESET_LICENSE_FAILED:
      return {
        ...state,
        error: action.error,
        isReseting: false
      };
    //!Delete license
    case TypeActions.DELETE_LICENSE_REQUEST:
      return {
        ...state,
        isDeleting: true
      };
    case TypeActions.DELETE_LICENSE_SUCCESS:
      return {
        ...state,
        isDeleting: false
      };
    case TypeActions.DELETE_LICENSE_FAILED:
      return {
        ...state,
        error: action.error,
        isDeleting: false
      };
    //!Default
    default:
      return {
        ...state
      };
  }
};

export default licenseReducer;
