import TypeActions from '../TypeActions';

const initialState = {
  listAgency: [],
  agencyById: {},
  statistics: [],
  statistic: {},
  agencyZeros: [],
  isGetting: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  isGetStatistics: false,
  isGetStatistic: false,
  isGetAgencyZeros: false,
  error: ''
};

export const agencyReducer = (state = initialState, action) => {
  switch (action.type) {
    //!Get list agency
    case TypeActions.GET_LIST_AGENCY_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_LIST_AGENCY_SUCCESS:
      return {
        ...state,
        listAgency: action.data,
        isGetting: false
      };
    case TypeActions.GET_LIST_AGENCY_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Get agency
    case TypeActions.GET_AGENCY_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_AGENCY_SUCCESS:
      return {
        ...state,
        agencyById: action.data,
        isGetting: false
      };
    case TypeActions.GET_AGENCY_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Create agency
    case TypeActions.CREATE_AGENCY_REQUEST:
      return {
        ...state,
        isCreating: true
      };
    case TypeActions.CREATE_AGENCY_SUCCESS:
      return {
        ...state,
        isCreating: false
      };
    case TypeActions.CREATE_AGENCY_FAILED:
      return {
        ...state,
        error: action.error,
        isCreating: false
      };
    //!Edit agency
    case TypeActions.EDIT_AGENCY_REQUEST:
      return {
        ...state,
        isEditing: true
      };
    case TypeActions.EDIT_AGENCY_SUCCESS:
      return {
        ...state,
        isEditing: false
      };
    case TypeActions.EDIT_AGENCY_FAILED:
      return {
        ...state,
        error: action.error,
        isEditing: false
      };
    //!Delete agency
    case TypeActions.DELETE_AGENCY_REQUEST:
      return {
        ...state,
        isDeleting: true
      };
    case TypeActions.DELETE_AGENCY_SUCCESS:
      return {
        ...state,
        isDeleting: false
      };
    case TypeActions.DELETE_AGENCY_FAILED:
      return {
        ...state,
        error: action.error,
        isDeleting: false
      };
    // get statistics
    case TypeActions.GET_STATISTICS_AGENCY_REQUEST:
      return {
        ...state,
        isGetStatistics: true
      };
    case TypeActions.GET_STATISTICS_AGENCY_SUCCESS:
      return {
        ...state,
        statistics: action.data,
        isGetStatistics: false
      };
    case TypeActions.GET_STATISTICS_AGENCY_FAILED:
      return {
        ...state,
        error: action.error,
        isGetStatistics: false
      };
    //!Get agency
    case TypeActions.GET_STATISTIC_AGENCY_REQUEST:
      return {
        ...state,
        isGetStatistic: true
      };
    case TypeActions.GET_STATISTIC_AGENCY_SUCCESS:
      return {
        ...state,
        statistic: action.data,
        isGetStatistic: false
      };
    case TypeActions.GET_STATISTIC_AGENCY_FAILED:
      return {
        ...state,
        error: action.error,
        isGetStatistic: false
      };

    // get agency zero not active license
    case TypeActions.GET_AGENCY_ZERO_ACTIVE_REQUEST:
      return {
        ...state,
        isGetAgencyZeros: true
      };
    case TypeActions.GET_AGENCY_ZERO_ACTIVE_SUCCESS:
      return {
        ...state,
        agencyZeros: action.data,
        isGetAgencyZeros: false
      };
    case TypeActions.GET_AGENCY_ZERO_ACTIVE_FAILED:
      return {
        ...state,
        error: action.error,
        isGetAgencyZeros: false
      };
    //!Default
    default:
      return {
        ...state
      };
  }
};

export default agencyReducer;
