import TypeActions from '../TypeActions';

const initialState = {
  listDevices: [],
  listDivicesReceive: [],
  listDivicesHistory: [],
  deviceById: {},
  isGetting: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  isConverting: false,
  isUpdateSubIdDevice: false,
  error: ''
};

export const guaranteeReducer = (state = initialState, action) => {
  switch (action.type) {
    //!Get list device
    case TypeActions.GET_LIST_DEVICE_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_LIST_DEVICE_SUCCESS:
      return {
        ...state,
        listDevices: action.data,
        isGetting: false
      };
    case TypeActions.GET_LIST_DEVICE_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Get list device receive
    case TypeActions.GET_LIST_DEVICE_RECEIVE_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_LIST_DEVICE_RECEIVE_SUCCESS:
      return {
        ...state,
        listDivicesReceive: action.data,
        isGetting: false
      };
    case TypeActions.GET_LIST_DEVICE_RECEIVE_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Get list device history
    case TypeActions.GET_LIST_DEVICE_HISTORY_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_LIST_DEVICE_HISTORY_SUCCESS:
      return {
        ...state,
        listDivicesHistory: action.data,
        isGetting: false
      };
    case TypeActions.GET_LIST_DEVICE_HISTORY_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Receive device
    case TypeActions.RECEIVE_DEVICE_REQUEST:
      return {
        ...state,
        isCreating: true
      };
    case TypeActions.RECEIVE_DEVICE_SUCCESS:
      return {
        ...state,
        listDivices: action.data,
        isCreating: false
      };
    case TypeActions.RECEIVE_DEVICE_FAILED:
      return {
        ...state,
        error: action.error,
        isCreating: false
      };
    //!Convert device
    case TypeActions.CONVERT_DEVICE_REQUEST:
      return {
        ...state,
        isConverting: true
      };
    case TypeActions.CONVERT_DEVICE_SUCCESS:
      return {
        ...state,
        isConverting: false
      };
    case TypeActions.CONVERT_DEVICE_FAILED:
      return {
        ...state,
        error: action.error,
        isConverting: false
      };
    //!Get device
    case TypeActions.GET_DEVICE_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_DEVICE_SUCCESS:
      return {
        ...state,
        deviceById: action.data,
        isGetting: false
      };
    case TypeActions.GET_DEVICE_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Create device
    case TypeActions.CREATE_DEVICE_REQUEST:
      return {
        ...state,
        isCreating: true
      };
    case TypeActions.CREATE_DEVICE_SUCCESS:
      return {
        ...state,
        isCreating: false
      };
    case TypeActions.CREATE_DEVICE_FAILED:
      return {
        ...state,
        error: action.error,
        isCreating: false
      };
    //!Edit device
    case TypeActions.EDIT_DEVICE_REQUEST:
      return {
        ...state,
        isEditing: true
      };
    case TypeActions.EDIT_DEVICE_SUCCESS:
      return {
        ...state,
        isEditing: false
      };
    case TypeActions.EDIT_DEVICE_FAILED:
      return {
        ...state,
        error: action.error,
        isEditing: false
      };
    //!unReceive Guarantee Device
    case TypeActions.DELETE_DEVICE_REQUEST:
      return {
        ...state,
        isDeleting: true
      };
    case TypeActions.DELETE_DEVICE_SUCCESS:
      return {
        ...state,
        isDeleting: false
      };
    case TypeActions.DELETE_DEVICE_FAILED:
      return {
        ...state,
        error: action.error,
        isDeleting: false
      };
    //!Update Sub Id Device
    case TypeActions.UPDATE_SUB_ID_DEVICE_REQUEST:
      return {
        ...state,
        isUpdateSubIdDevice: true
      };
    case TypeActions.UPDATE_SUB_ID_DEVICE_SUCCESS:
      return {
        ...state,
        isUpdateSubIdDevice: false
      };
    case TypeActions.UPDATE_SUB_ID_DEVICE_FAILED:
      return {
        ...state,
        error: action.error,
        isUpdateSubIdDevice: false
      };
    //!Default
    default:
      return {
        ...state
      };
  }
};

export default guaranteeReducer;
