import { all, fork } from 'redux-saga/effects';
import roleSaga from './roleSaga';
import userSaga from './userSaga';
import agencySaga from './agencySaga';
import customerSaga from './customerSaga';
import licenseSaga from './licenseSaga';
import guaranteeSaga from './guaranteeSaga';
import feedbackSaga from './feedbackSaga';
import provinceSaga from './provinceSaga';
import dashboardSaga from './dashboardSaga';
import applicationSaga from './applicationSaga';

export function* rootSagas() {
  yield all([
    fork(userSaga),
    fork(roleSaga),
    fork(agencySaga),
    fork(licenseSaga),
    fork(guaranteeSaga),
    fork(customerSaga),
    fork(feedbackSaga),
    fork(provinceSaga),
    fork(dashboardSaga),
    fork(applicationSaga)
  ]);
}
export default rootSagas;
