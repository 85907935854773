import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  colors,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import ReactLoading from 'react-loading';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import userActions from 'src/Redux/Actions/userActions';
import * as yup from 'yup';

const useStyles = makeStyles(theme => ({
  root: {
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
    // padding: theme.spacing(3)
  },
  // card: {
  //   margin: theme.spacing(3),
  //   marginRight: theme.spacing(3),
  //   marginLeft: theme.spacing(3)
  // },
  fields: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    // margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    },
    marginRight: '3px',
    marginLeft: '3px'
  },
  botButton: {
    display: 'flex',
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  buttonSuccess: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundColor: '#e53935',
    '&:hover': {
      backgroundColor: colors.red[900],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: colors.red[600]
      }
    }
  },
  buttonGrey: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundColor: colors.grey[600],
    '&:hover': {
      backgroundColor: colors.grey[900],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: colors.grey[600]
      }
    }
  },
  media: {
    height: 126
  },
  avatar: {
    height: 200,
    width: 200,
    marginBottom: theme.spacing(1),
    border: `4px solid ${theme.palette.common.white}`
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: -60
  },
  selectLabel: {
    // fontSize: "12px",
    // textTransform: "uppercase",
    // color: grayColor[1] + " !important",
    top: '-7px',
    // bottom: '15px',
    // ...defaultFont,
    color: colors.grey[3] + ' !important',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
    padding: '0 0 0 15px'
  },
  gridItemLabel: {
    alignItems: 'center',
    display: 'flex',
    '& > span': {
      fontWeight: '400'
    }
  },
  title: {
    margin: '1rem 0rem'
  }
}));

function ChangePassword({ className, dataUser, ...rest }) {
  //!Const
  const history = useHistory();
  const classes = useStyles();
  const { idUser } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.userReducer.isLoading);

  //!State
  const [changePass, setschangePass] = React.useState({
    oldPassword: '',
    newPassword: '',
    newPasswordRetype: ''
  });
  const passSchema = yup.object().shape({
    oldPassword: yup.string().required('Vui lòng nhập mật khẩu hiện tại!'),
    newPassword: yup
      .string()
      .required('Vui lòng nhập mật khẩu mới!')
      .matches(
        '[^wd]*(([0-9]+.*[A-Za-z]+.*)|[A-Za-z]+.*([0-9]+.*))',
        'Mật khẩu phải chứa ít nhất một chữ và một số!'
      )
      .min(8, 'Mật khẩu phải có ít nhất 8 ký tự!'),
    newPasswordRetype: yup
      .string()
      .oneOf([yup.ref('newPassword')], 'Mật khẩu không trùng khớp!')
      .required('Vui lòng xác nhận mật khẩu!')
  });
  //!Function
  const handleChange = value => {
    const password = {
      oldPassword: value.oldPassword,
      newPassword: value.newPassword
    };
    dispatch(
      userActions.changePassword(password, idUser, {
        success: () => {
          store.addNotification({
            title: 'Thông báo!',
            message:
              'Cập nhật mật khẩu thành công! Vui lòng đăng nhập lại hệ thống!',
            type: 'success',
            container: 'bottom-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              pauseOnHover: true,
              onScreen: true,
              duration: 5000
            }
          });
          // props.setopenPopup(false);
          dispatch(
            userActions.logout({
              refreshToken: localStorage.getItem('refreshtoken')
            })
          );
          history.push('/auth/login');
        },
        failed: mess => {
          store.addNotification({
            title: 'Thông báo!',
            message:
              'Cập nhật mật khẩu thất bại! Lỗi: ' +
              mess +
              '! Vui lòng thử lại!',
            type: 'danger',
            container: 'bottom-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              onScreen: true,
              pauseOnHover: true,
              duration: 5000
            }
          });
        }
      })
    );
  };

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid item lg={1} md={1} xs={0}></Grid>
      <Grid item lg={3} md={3} xs={12}>
        <Card>
          <CardMedia
            className={classes.media}
            image="/images/covers/cover_2.jpg"
          />
          <CardContent className={classes.content}>
            <Avatar
              alt="Person"
              // onClick={handleStatusToggle}
              className={classes.avatar}
              src={
                'https://st.quantrimang.com/photos/image/072015/22/avatar.jpg'
              }
            />
            <Typography gutterBottom variant="h3">
              {dataUser && dataUser.name}
            </Typography>
            <Typography variant="body2">
              {dataUser && dataUser.email}
            </Typography>
          </CardContent>
          <Divider />
          {/* <CardContent>{dataUser.name} </CardContent> */}
        </Card>
      </Grid>
      <Grid item lg={7} md={7} xs={12}>
        <Card>
          <CardContent>
            <Formik
              enableReinitialize
              initialValues={changePass}
              validationSchema={passSchema}
              onSubmit={handleChange}
            >
              {({ values, setFieldValue, handleSubmit, errors, touched }) => {
                return (
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      {dataUser.isPasswordChange === false ? (
                        <a
                          style={{
                            fontSize: 14,
                            color: 'red',
                            padding: '10px',
                            letterSpacing: '0.33px',
                            fontFamily: 'roboto'
                          }}
                        >
                          Vui lòng thay đổi mật khẩu trước khi sử dụng hệ thống!
                        </a>
                      ) : null}
                      <div className={classes.fields}>
                        <TextField
                          variant="outlined"
                          id="oldPassword"
                          label="Mật khẩu hiện tại *"
                          value={values.oldPassword}
                          //   style={{ color: "white" }}
                          onChange={text =>
                            setFieldValue('oldPassword', text.target.value)
                          }
                          fullWidth
                          type="password"
                        />
                        {errors.oldPassword && touched.oldPassword && (
                          <a
                            style={{
                              fontFamily: 'roboto',
                              margin: '0px 0px 15px 8px',
                              fontSize: '11px',
                              fontWeight: 400,
                              letterSpacing: '0.33px',
                              color: 'red'
                            }}
                          >
                            {errors.oldPassword}
                          </a>
                        )}
                        <TextField
                          variant="outlined"
                          id="newPassword"
                          label="Mật khẩu mới *"
                          value={values.newPassword}
                          //   style={{ color: "white" }}
                          onChange={text =>
                            setFieldValue('newPassword', text.target.value)
                          }
                          fullWidth
                          type="password"
                        />
                        {errors.newPassword && touched.newPassword && (
                          <a
                            style={{
                              fontFamily: 'roboto',
                              margin: '0px 0px 15px 8px',
                              fontSize: '11px',
                              fontWeight: 400,
                              letterSpacing: '0.33px',
                              color: 'red'
                            }}
                          >
                            {errors.newPassword}
                          </a>
                        )}
                        <TextField
                          variant="outlined"
                          id="newPasswordRetype"
                          label="Nhập lại mật khẩu mới *"
                          value={values.newPasswordRetype}
                          //   style={{ color: "white" }}
                          onChange={text =>
                            setFieldValue(
                              'newPasswordRetype',
                              text.target.value
                            )
                          }
                          fullWidth
                          type="password"
                        />
                        {errors.newPasswordRetype && touched.newPasswordRetype && (
                          <a
                            style={{
                              fontFamily: 'roboto',
                              margin: '0px 0px 15px 8px',
                              fontSize: '11px',
                              fontWeight: 400,
                              letterSpacing: '0.33px',
                              color: 'red'
                            }}
                          >
                            {errors.newPasswordRetype}
                          </a>
                        )}
                      </div>
                      <div
                        className={classes.container}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '15px'
                        }}
                      >
                        {isLoading ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              marginTop: '10px'
                            }}
                          >
                            <ReactLoading
                              type="spin"
                              color="black"
                              height="30px"
                              width="30px"
                            />
                          </div>
                        ) : (
                          <>
                            <Button
                              className={classes.buttonGrey}
                              onClick={() => {
                                setFieldValue('oldPassword', '');
                                setFieldValue('newPassword', '');
                                setFieldValue('newPasswordRetype', '');
                                history.push(`/setting/user/${idUser}/infor`);
                              }}
                            >
                              Huỷ bỏ
                            </Button>
                            &nbsp;
                            <Button
                              className={classes.buttonSuccess}
                              //   round
                              onClick={handleSubmit}
                            >
                              Cập nhật
                            </Button>
                          </>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                );
              }}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={1} md={1} xs={0}></Grid>
    </Grid>
  );
}

ChangePassword.propTypes = {
  className: PropTypes.string
};

export default ChangePassword;
