/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';
import ErrorLayout from './layouts/Error';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/containers/Login'))
      },
      {
        path: '/auth/forgot-password',
        exact: true,
        component: lazy(() => import('src/containers/ForgotPass'))
      },
      {
        path: '/auth/reset-password',
        exact: true,
        component: lazy(() => import('src/containers/ResetPass'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('src/views/Register'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      //!New
      {
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('./containers/Dashboard'))
      },
      {
        path: '/feedback',
        exact: true,
        component: lazy(() => import('./containers/Feedback'))
      },
      {
        path: '/products/device-history',
        exact: true,
        component: lazy(() => import('./containers/DeviceHistory'))
      },
      {
        path: '/user-manage/user',
        exact: true,
        component: lazy(() => import('./containers/UserManage'))
      },
      {
        path: '/user-manage/user/:userId',
        exact: true,
        component: lazy(() => import('./containers/UserManage/AddUser'))
      },
      {
        path: '/user-manage/role',
        exact: true,
        component: lazy(() => import('./containers/RoleManage'))
      },
      {
        path: '/user-manage/role/:roleId',
        exact: true,
        component: lazy(() => import('./containers/RoleManage/AddRole'))
      },
      {
        path: '/config-facility/agency',
        exact: true,
        component: lazy(() => import('./containers/AgencyManage'))
      },
      {
        path: '/config-facility/agency/:agencyId',
        exact: true,
        component: lazy(() => import('./containers/AgencyManage/AddAgency'))
      },
      {
        path: '/config-facility/customer',
        exact: true,
        component: lazy(() => import('./containers/CustomerManage'))
      },
      {
        path: '/config-facility/customer/:customerId',
        exact: true,
        component: lazy(() => import('./containers/CustomerManage/AddCustomer'))
      },
      {
        path: '/config-facility/statistics',
        exact: true,
        component: lazy(() => import('./containers/Statistic'))
      },
      {
        path: '/config-facility/statistics/:statisticId',
        exact: true,
        component: lazy(() => import('./containers/StatisticDetail'))
      },
      {
        path: '/setting/user/:idUser/:tab',
        exact: true,
        component: lazy(() => import('./containers/UserSetting'))
      },
      {
        path: '/products/actived-manage',
        exact: true,
        component: lazy(() => import('./containers/LicenseManage'))
      },
      {
        path: '/products/cate',
        exact: true,
        component: lazy(() =>
          import('./containers/GuaranteeeManage/AllProduct')
        )
      },
      {
        path: '/products/guarantee-manage',
        exact: true,
        component: lazy(() => import('./containers/GuaranteeeManage'))
      },
      {
        path: '/config-facility/customers-manage',
        exact: true,
        component: lazy(() => import('./containers/CustomerManage'))
      },
      {
        path: '/config-facility/customers-manage/:customerId',
        exact: true,
        component: lazy(() => import('./containers/CustomerManage/AddCustomer'))
      },
      {
        path: '/application',
        exact: true,
        component: lazy(() => import('./containers/Application'))
      }
    ]
  }
];
