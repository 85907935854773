import TypeActions from '../TypeActions';

export const getListFeedback = params => {
  return {
    type: TypeActions.GET_LIST_FEEDBACK_REQUEST,
    params
    // callback
  };
};
export const getFeedback = params => {
  return {
    type: TypeActions.GET_FEEDBACK_REQUEST,
    params
  };
};

export const createFeedback = (body, callback) => {
  return {
    type: TypeActions.CREATE_FEEDBACK_REQUEST,
    body,
    callback
  };
};
export const editFeedback = (body, params, callback) => {
  return {
    type: TypeActions.EDIT_FEEDBACK_REQUEST,
    body,
    params,
    callback
  };
};
export const deleteFeedback = (params, callback) => {
  return {
    type: TypeActions.DELETE_FEEDBACK_REQUEST,
    params,
    callback
  };
};

export const viewedFeedback = id => {
  return {
    type: TypeActions.VIEWED_FEEDBACK_SUCCESS,
    id
  };
};

export default {
  getListFeedback,
  createFeedback,
  editFeedback,
  deleteFeedback,
  getFeedback,
  viewedFeedback
};
