import TypeActions from '../TypeActions';

export const getApplications = query => {
  return {
    type: TypeActions.GET_APPLICATIONS_REQUEST,
    query
    // callback
  };
};
export const getApplication = param => {
  return {
    type: TypeActions.GET_APPLICATION_REQUEST,
    param
  };
};

export const createApplication = (body, callback) => {
  return {
    type: TypeActions.CREATE_APPLICATION_REQUEST,
    body,
    callback
  };
};
export const updateApplication = (param, body, callback) => {
  return {
    type: TypeActions.UPDATE_APPLICATION_REQUEST,
    body,
    param,
    callback
  };
};
export const deleteApplication = (param, callback) => {
  return {
    type: TypeActions.DELETE_APPLICATION_REQUEST,
    param,
    callback
  };
};

export const uploadImageApplication = (body, callback) => {
  return {
    type: TypeActions.UPLOAD_APPLICATION_IMAGE_REQUEST,
    body,
    callback
  };
};

export const uploadFileApplication = (body, callback) => {
  return {
    type: TypeActions.UPLOAD_APPLICATION_FILE_REQUEST,
    body,
    callback
  };
};

export default {
  getApplications,
  createApplication,
  updateApplication,
  deleteApplication,
  getApplication,
  uploadImageApplication
};
