import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DELETE, GET, PATCH, POST } from 'src/Service/ServiceBase';
import ServiceURL from 'src/Service/ServiceURL';
import TypeActions from '../TypeActions';

export function* getListLicense(data) {
  const url = ServiceURL.License + '?' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LIST_LICENSE_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_LIST_LICENSE_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LIST_LICENSE_FAILED,
      error: error.response.data.message
    });
  }
}

export function* getLicense(data) {
  const url = ServiceURL.License + '/' + data.params;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LICENSE_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_LICENSE_SUCCESS,
        data: res.data
      });
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LICENSE_FAILED,
      error: error.response.data.message
    });
  }
}

export function* createLicense(data) {
  const url = ServiceURL.License;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_LICENSE_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.CREATE_LICENSE_SUCCESS,
        data: res.data
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_LICENSE_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* editLicense(data) {
  const url = ServiceURL.License + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EDIT_LICENSE_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.EDIT_LICENSE_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.EDIT_LICENSE_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* resetLicense(data) {
  const url = ServiceURL.License + '/resetLicense/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.RESET_LICENSE_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.RESET_LICENSE_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.RESET_LICENSE_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* deleteLicense(data) {
  const url = ServiceURL.License + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.DELETE_LICENSE_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.DELETE_LICENSE_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.DELETE_LICENSE_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export default function* licenseSaga() {
  yield takeLatest(TypeActions.GET_LIST_LICENSE_REQUEST, getListLicense);
  yield takeLatest(TypeActions.GET_LICENSE_REQUEST, getLicense);
  yield takeLatest(TypeActions.CREATE_LICENSE_REQUEST, createLicense);
  yield takeLatest(TypeActions.EDIT_LICENSE_REQUEST, editLicense);
  yield takeLatest(TypeActions.RESET_LICENSE_REQUEST, resetLicense);
  yield takeLatest(TypeActions.DELETE_LICENSE_REQUEST, deleteLicense);
}
