export default {
  //!Users
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_REQUEST_SUCCESS: 'LOGIN_REQUEST_SUCCESS',
  LOGIN_REQUEST_FAILED: 'LOGIN_REQUEST_FAILED',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_REQUEST_SUCCESS: 'LOGOUT_REQUEST_SUCCESS',
  LOGOUT_REQUEST_FAILED: 'LOGOUT_REQUEST_FAILED',
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILED: 'GET_USERS_FAILED',
  GET_USERS_INFOR_REQUEST: 'GET_USERS_INFOR_REQUEST',
  GET_USERS_INFOR_SUCCESS: 'GET_USERS_INFOR_SUCCESS',
  GET_USERS_INFOR_FAILED: 'GET_USERS_INFOR_FAILED',
  CHANGE_PASSWORD_USERS_REQUEST: 'CHANGE_PASSWORD_USERS_REQUEST',
  CHANGE_PASSWORD_USERS_SUCCESS: 'CHANGE_PASSWORD_USERS_SUCCESS',
  CHANGE_PASSWORD_USERS_FAILED: 'CHANGE_PASSWORD_USERS_FAILED',
  FORGOT_PASSWORD_USERS_REQUEST: 'FORGOT_PASSWORD_USERS_REQUEST',
  FORGOT_PASSWORD_USERS_SUCCESS: 'FORGOT_PASSWORD_USERS_SUCCESS',
  FORGOT_PASSWORD_USERS_FAILED: 'FORGOT_PASSWORD_USERS_FAILED',
  RESET_PASSWORD_USERS_REQUEST: 'RESET_PASSWORD_USERS_REQUEST',
  RESET_PASSWORD_USERS_SUCCESS: 'RESET_PASSWORD_USERS_SUCCESS',
  RESET_PASSWORD_USERS_FAILED: 'RESET_PASSWORD_USERS_FAILED',
  GET_LIST_USERS_REQUEST: 'GET_LIST_USERS_REQUEST',
  GET_LIST_USERS_SUCCESS: 'GET_LIST_USERS_SUCCESS',
  GET_LIST_USERS_FAILED: 'GET_LIST_USERS_FAILED',
  CREATE_USERS_REQUEST: 'CREATE_USERS_REQUEST',
  CREATE_USERS_SUCCESS: 'CREATE_USERS_SUCCESS',
  CREATE_USERS_FAILED: 'CREATE_USERS_FAILED',
  EDIT_USERS_REQUEST: 'EDIT_USERS_REQUEST',
  EDIT_USERS_SUCCESS: 'EDIT_USERS_SUCCESS',
  EDIT_USERS_FAILED: 'EDIT_USERS_FAILED',
  DELETE_USERS_REQUEST: 'DELETE_USERS_REQUEST',
  DELETE_USERS_SUCCESS: 'DELETE_USERS_SUCCESS',
  DELETE_USERS_FAILED: 'DELETE_USERS_FAILED',
  UPLOAD_IMAGE_REQUEST: 'UPLOAD_IMAGE_REQUEST',
  UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAILED: 'UPLOAD_IMAGE_FAILED',
  SET_PASS_USER_REQUEST: 'SET_PASS_USER_REQUEST',
  SET_PASS_USER_SUCCESS: 'SET_PASS_USER_SUCCESS',
  SET_PASS_USER_FAILED: 'SET_PASS_USER_FAILED',

  //!Role
  GET_LIST_ROLE_REQUEST: 'GET_LIST_ROLE_REQUEST',
  GET_LIST_ROLE_SUCCESS: 'GET_LIST_ROLE_SUCCESS',
  GET_LIST_ROLE_FAILED: 'GET_LIST_ROLE_FAILED',
  GET_ROLE_REQUEST: 'GET_ROLE_REQUEST',
  GET_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
  GET_ROLE_FAILED: 'GET_ROLE_FAILED',
  CREATE_ROLE_REQUEST: 'CREATE_ROLE_REQUEST',
  CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAILED: 'CREATE_ROLE_FAILED',
  EDIT_ROLE_REQUEST: 'EDIT_ROLE_REQUEST',
  EDIT_ROLE_SUCCESS: 'EDIT_ROLE_SUCCESS',
  EDIT_ROLE_FAILED: 'EDIT_ROLE_FAILED',
  DELETE_ROLE_REQUEST: 'DELETE_ROLE_REQUEST',
  DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAILED: 'DELETE_ROLE_FAILED',
  GET_ROLE_PERMISSION_REQUEST: 'GET_ROLE_PERMISSION_REQUEST',
  GET_ROLE_PERMISSION_SUCCESS: 'GET_ROLE_PERMISSION_SUCCESS',
  GET_ROLE_PERMISSION_FAILED: 'GET_ROLE_PERMISSION_FAILED',

  //!Agency
  GET_LIST_AGENCY_REQUEST: 'GET_LIST_AGENCY_REQUEST',
  GET_LIST_AGENCY_SUCCESS: 'GET_LIST_AGENCY_SUCCESS',
  GET_LIST_AGENCY_FAILED: 'GET_LIST_AGENCY_FAILED',
  GET_AGENCY_REQUEST: 'GET_AGENCY_REQUEST',
  GET_AGENCY_SUCCESS: 'GET_AGENCY_SUCCESS',
  GET_AGENCY_FAILED: 'GET_AGENCY_FAILED',
  CREATE_AGENCY_REQUEST: 'CREATE_AGENCY_REQUEST',
  CREATE_AGENCY_SUCCESS: 'CREATE_AGENCY_SUCCESS',
  CREATE_AGENCY_FAILED: 'CREATE_AGENCY_FAILED',
  EDIT_AGENCY_REQUEST: 'EDIT_AGENCY_REQUEST',
  EDIT_AGENCY_SUCCESS: 'EDIT_AGENCY_SUCCESS',
  EDIT_AGENCY_FAILED: 'EDIT_AGENCY_FAILED',
  DELETE_AGENCY_REQUEST: 'DELETE_AGENCY_REQUEST',
  DELETE_AGENCY_SUCCESS: 'DELETE_AGENCY_SUCCESS',
  DELETE_AGENCY_FAILED: 'DELETE_AGENCY_FAILED',
  GET_STATISTICS_AGENCY_REQUEST: 'GET_STATISTICS_AGENCY_REQUEST',
  GET_STATISTICS_AGENCY_SUCCESS: 'GET_STATISTICS_AGENCY_SUCCESS',
  GET_STATISTICS_AGENCY_FAILED: 'GET_STATISTICS_AGENCY_FAILED',
  GET_STATISTIC_AGENCY_REQUEST: 'GET_STATISTIC_AGENCY_REQUEST',
  GET_STATISTIC_AGENCY_SUCCESS: 'GET_STATISTIC_AGENCY_SUCCESS',
  GET_STATISTIC_AGENCY_FAILED: 'GET_STATISTIC_AGENCY_FAILED',
  GET_AGENCY_ZERO_ACTIVE_REQUEST: 'GET_AGENCY_ZERO_ACTIVE_REQUEST',
  GET_AGENCY_ZERO_ACTIVE_SUCCESS: 'GET_AGENCY_ZERO_ACTIVE_SUCCESS',
  GET_AGENCY_ZERO_ACTIVE_FAILED: 'GET_AGENCY_ZERO_ACTIVE_FAILED',

  //!Customer
  GET_LIST_CUSTOMER_REQUEST: 'GET_LIST_CUSTOMER_REQUEST',
  GET_LIST_CUSTOMER_SUCCESS: 'GET_LIST_CUSTOMER_SUCCESS',
  GET_LIST_CUSTOMER_FAILED: 'GET_LIST_CUSTOMER_FAILED',
  GET_CUSTOMER_REQUEST: 'GET_CUSTOMER_REQUEST',
  GET_CUSTOMER_SUCCESS: 'GET_CUSTOMER_SUCCESS',
  GET_CUSTOMER_FAILED: 'GET_CUSTOMER_FAILED',
  CREATE_CUSTOMER_REQUEST: 'CREATE_CUSTOMER_REQUEST',
  CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_FAILED: 'CREATE_CUSTOMER_FAILED',
  EDIT_CUSTOMER_REQUEST: 'EDIT_CUSTOMER_REQUEST',
  EDIT_CUSTOMER_SUCCESS: 'EDIT_CUSTOMER_SUCCESS',
  EDIT_CUSTOMER_FAILED: 'EDIT_CUSTOMER_FAILED',
  DELETE_CUSTOMER_REQUEST: 'DELETE_CUSTOMER_REQUEST',
  DELETE_CUSTOMER_SUCCESS: 'DELETE_CUSTOMER_SUCCESS',
  DELETE_CUSTOMER_FAILED: 'DELETE_CUSTOMER_FAILED',

  //!Feedback
  GET_LIST_FEEDBACK_REQUEST: 'GET_LIST_FEEDBACK_REQUEST',
  GET_LIST_FEEDBACK_SUCCESS: 'GET_LIST_FEEDBACK_SUCCESS',
  GET_LIST_FEEDBACK_FAILED: 'GET_LIST_FEEDBACK_FAILED',
  GET_FEEDBACK_REQUEST: 'GET_FEEDBACK_REQUEST',
  GET_FEEDBACK_SUCCESS: 'GET_FEEDBACK_SUCCESS',
  GET_FEEDBACK_FAILED: 'GET_FEEDBACK_FAILED',
  CREATE_FEEDBACK_REQUEST: 'CREATE_FEEDBACK_REQUEST',
  CREATE_FEEDBACK_SUCCESS: 'CREATE_FEEDBACK_SUCCESS',
  CREATE_FEEDBACK_FAILED: 'CREATE_FEEDBACK_FAILED',
  EDIT_FEEDBACK_REQUEST: 'EDIT_FEEDBACK_REQUEST',
  EDIT_FEEDBACK_SUCCESS: 'EDIT_FEEDBACK_SUCCESS',
  EDIT_FEEDBACK_FAILED: 'EDIT_FEEDBACK_FAILED',
  DELETE_FEEDBACK_REQUEST: 'DELETE_FEEDBACK_REQUEST',
  DELETE_FEEDBACK_SUCCESS: 'DELETE_FEEDBACK_SUCCESS',
  DELETE_FEEDBACK_FAILED: 'DELETE_FEEDBACK_FAILED',

  //!Guarantee
  //!Devices
  GET_LIST_DEVICE_REQUEST: 'GET_LIST_DEVICE_REQUEST',
  GET_LIST_DEVICE_SUCCESS: 'GET_LIST_DEVICE_SUCCESS',
  GET_LIST_DEVICE_FAILED: 'GET_LIST_DEVICE_FAILED',
  GET_DEVICE_REQUEST: 'GET_DEVICE_REQUEST',
  GET_DEVICE_SUCCESS: 'GET_DEVICE_SUCCESS',
  GET_DEVICE_FAILED: 'GET_DEVICE_FAILED',
  CREATE_DEVICE_REQUEST: 'CREATE_DEVICE_REQUEST',
  CREATE_DEVICE_SUCCESS: 'CREATE_DEVICE_SUCCESS',
  CREATE_DEVICE_FAILED: 'CREATE_DEVICE_FAILED',
  EDIT_DEVICE_REQUEST: 'EDIT_DEVICE_REQUEST',
  EDIT_DEVICE_SUCCESS: 'EDIT_DEVICE_SUCCESS',
  EDIT_DEVICE_FAILED: 'EDIT_DEVICE_FAILED',
  DELETE_DEVICE_REQUEST: 'DELETE_DEVICE_REQUEST',
  DELETE_DEVICE_SUCCESS: 'DELETE_DEVICE_SUCCESS',
  DELETE_DEVICE_FAILED: 'DELETE_DEVICE_FAILED',
  RECEIVE_DEVICE_REQUEST: 'RECEIVE_DEVICE_REQUEST',
  RECEIVE_DEVICE_SUCCESS: 'RECEIVE_DEVICE_SUCCESS',
  RECEIVE_DEVICE_FAILED: 'RECEIVE_DEVICE_FAILED',
  CONVERT_DEVICE_REQUEST: 'CONVERT_DEVICE_REQUEST',
  CONVERT_DEVICE_SUCCESS: 'CONVERT_DEVICE_SUCCESS',
  CONVERT_DEVICE_FAILED: 'CONVERT_DEVICE_FAILED',
  GET_LIST_DEVICE_RECEIVE_REQUEST: 'GET_LIST_DEVICE_RECEIVE_REQUEST',
  GET_LIST_DEVICE_RECEIVE_SUCCESS: 'GET_LIST_DEVICE_RECEIVE_SUCCESS',
  GET_LIST_DEVICE_RECEIVE_FAILED: 'GET_LIST_DEVICE_RECEIVE_FAILED',
  GET_LIST_DEVICE_HISTORY_REQUEST: 'GET_LIST_DEVICE_HISTORY_REQUEST',
  GET_LIST_DEVICE_HISTORY_SUCCESS: 'GET_LIST_DEVICE_HISTORY_SUCCESS',
  GET_LIST_DEVICE_HISTORY_FAILED: 'GET_LIST_DEVICE_HISTORY_FAILED',

  //!License
  GET_LIST_LICENSE_REQUEST: 'GET_LIST_LICENSE_REQUEST',
  GET_LIST_LICENSE_SUCCESS: 'GET_LIST_LICENSE_SUCCESS',
  GET_LIST_LICENSE_FAILED: 'GET_LIST_LICENSE_FAILED',
  GET_LICENSE_REQUEST: 'GET_LICENSE_REQUEST',
  GET_LICENSE_SUCCESS: 'GET_LICENSE_SUCCESS',
  GET_LICENSE_FAILED: 'GET_LICENSE_FAILED',
  CREATE_LICENSE_REQUEST: 'CREATE_LICENSE_REQUEST',
  CREATE_LICENSE_SUCCESS: 'CREATE_LICENSE_SUCCESS',
  CREATE_LICENSE_FAILED: 'CREATE_LICENSE_FAILED',
  EDIT_LICENSE_REQUEST: 'EDIT_LICENSE_REQUEST',
  EDIT_LICENSE_SUCCESS: 'EDIT_LICENSE_SUCCESS',
  EDIT_LICENSE_FAILED: 'EDIT_LICENSE_FAILED',
  DELETE_LICENSE_REQUEST: 'DELETE_LICENSE_REQUEST',
  DELETE_LICENSE_SUCCESS: 'DELETE_LICENSE_SUCCESS',
  DELETE_LICENSE_FAILED: 'DELETE_LICENSE_FAILED',
  RESET_LICENSE_REQUEST: 'RESET_LICENSE_REQUEST',
  RESET_LICENSE_SUCCESS: 'RESET_LICENSE_SUCCESS',
  RESET_LICENSE_FAILED: 'RESET_LICENSE_FAILED',

  //!Province
  GET_LIST_PROVINCE_REQUEST: 'GET_LIST_PROVINCE_REQUEST',
  GET_LIST_PROVINCE_SUCCESS: 'GET_LIST_PROVINCE_SUCCESS',
  GET_LIST_PROVINCE_FAILED: 'GET_LIST_PROVINCE_FAILED',
  GET_LIST_DISTRICT_REQUEST: 'GET_LIST_DISTRICT_REQUEST',
  GET_LIST_DISTRICT_SUCCESS: 'GET_LIST_DISTRICT_SUCCESS',
  GET_LIST_DISTRICT_FAILED: 'GET_LIST_DISTRICT_FAILED',
  GET_LIST_WARD_REQUEST: 'GET_LIST_WARD_REQUEST',
  GET_LIST_WARD_SUCCESS: 'GET_LIST_WARD_SUCCESS',
  GET_LIST_WARD_FAILED: 'GET_LIST_WARD_FAILED',
  //!Dashboard
  GET_INFO_DASHBOARD_REQUEST: 'GET_INFO_DASHBOARD_REQUEST',
  GET_INFO_DASHBOARD_SUCCESS: 'GET_INFO_DASHBOARD_SUCCESS',
  GET_INFO_DASHBOARD_FAILED: 'GET_INFO_DASHBOARD_FAILED',

  // !Reset pin
  RESET_PIN_CUSTOMER_REQUEST: 'RESET_PIN_CUSTOMER_REQUEST',
  RESET_PIN_CUSTOMER_SUCCESS: 'RESET_PIN_CUSTOMER_SUCCESS',
  RESET_PIN_CUSTOMER_FAILED: 'RESET_PIN_CUSTOMER_FAILED',

  //!Update sub deviceId
  UPDATE_SUB_ID_DEVICE_REQUEST: 'UPDATE_SUB_ID_DEVICE_REQUEST',
  UPDATE_SUB_ID_DEVICE_SUCCESS: 'UPDATE_SUB_ID_DEVICE_SUCCESS',
  UPDATE_SUB_ID_DEVICE_FAILED: 'UPDATE_SUB_ID_DEVICE_FAILED',
  // Viewed feedback
  VIEWED_FEEDBACK_REQUEST: 'VIEWED_FEEDBACK_REQUEST',
  VIEWED_FEEDBACK_SUCCESS: 'VIEWED_FEEDBACK_SUCCESS',

  // Application
  GET_APPLICATIONS_REQUEST: 'GET_APPLICATIONS_REQUEST',
  GET_APPLICATIONS_SUCCESS: 'GET_APPLICATIONS_SUCCESS',
  GET_APPLICATIONS_FAILED: 'GET_APPLICATIONS_FAILED',

  GET_APPLICATION_REQUEST: 'GET_APPLICATION_REQUEST',
  GET_APPLICATION_SUCCESS: 'GET_APPLICATION_SUCCESS',
  GET_APPLICATION_FAILED: 'GET_APPLICATION_FAILED',

  CREATE_APPLICATION_REQUEST: 'CREATE_APPLICATION_REQUEST',
  CREATE_APPLICATION_SUCCESS: 'CREATE_APPLICATION_SUCCESS',
  CREATE_APPLICATION_FAILED: 'CREATE_APPLICATION_FAILED',

  UPDATE_APPLICATION_REQUEST: 'UPDATE_APPLICATION_REQUEST',
  UPDATE_APPLICATION_SUCCESS: 'UPDATE_APPLICATION_SUCCESS',
  UPDATE_APPLICATION_FAILED: 'UPDATE_APPLICATION_FAILED',

  DELETE_APPLICATION_REQUEST: 'DELETE_APPLICATION_REQUEST',
  DELETE_APPLICATION_SUCCESS: 'DELETE_APPLICATION_SUCCESS',
  DELETE_APPLICATION_FAILED: 'DELETE_APPLICATION_FAILED',

  UPLOAD_APPLICATION_IMAGE_REQUEST: 'UPLOAD_APPLICATION_IMAGE_REQUEST',
  UPLOAD_APPLICATION_IMAGE_SUCCESS: 'UPLOAD_APPLICATION_IMAGE_SUCCESS',
  UPLOAD_APPLICATION_IMAGE_FAILED: 'UPLOAD_APPLICATION_IMAGE_FAILED',

  UPLOAD_APPLICATION_FILE_REQUEST: 'UPLOAD_APPLICATION_FILE_REQUEST',
  UPLOAD_APPLICATION_FILE_SUCCESS: 'UPLOAD_APPLICATION_FILE_SUCCESS',
  UPLOAD_APPLICATION_FILE_FAILED: 'UPLOAD_APPLICATION_FILE_FAILED'
};
