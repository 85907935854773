/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

import DashboardIcon from '@material-ui/icons/DashboardOutlined';

import SettingsIcon from '@material-ui/icons/Settings';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import TabletIcon from '@material-ui/icons/Tablet';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';

export default [
  {
    subheader: 'Main',
    items: [
      {
        title: 'Bảng điều khiển',
        href: '/dashboard',
        icon: DashboardIcon,
        role: ''
      },
      //!Bravigo start-------------------------------------------
      {
        title: 'Quản lý sản phẩm',
        href: '/products',
        icon: TabletIcon,
        role: '',
        items: [
          {
            title: 'Quản lý kích hoạt mã',
            href: '/products/actived-manage',
            role: 'get_license'
          },
          {
            title: 'Danh mục sản phẩm',
            href: '/products/cate',
            role: 'get_license'
          },
          {
            title: 'Quản lý bảo hành sản phẩm',
            href: '/products/guarantee-manage',
            role: 'get_device'
          },
          {
            title: 'Lịch sử thiết bị',
            href: '/products/device-history',
            role: 'get_devicehistory'
          }
        ]
      },
      {
        title: 'Đại lý - Khách hàng',
        href: '/config-facility',
        role: '',
        icon: FolderSharedIcon,
        items: [
          {
            title: 'Quản lý đại lý',
            href: '/config-facility/agency',
            role: 'get_agency'
          },
          {
            title: 'Quản lý khách hàng',
            href: '/config-facility/customers-manage',
            role: 'get_customer'
          },
          {
            title: 'Báo cáo thống kê',
            href: '/config-facility/statistics',
            role: 'get_license'
          }
        ]
      },
      {
        title: 'Quản trị cơ sở',
        href: '/user-manage',
        icon: SettingsIcon,
        role: '',
        items: [
          {
            title: 'Quản lý tài khoản',
            href: '/user-manage/user',
            role: 'get_user'
          },
          {
            title: 'Quản lý phân quyền',
            href: '/user-manage/role',
            role: 'get_role'
          }
        ]
      },

      {
        title: 'Phản hồi khách hàng',
        role: 'get_feedback',
        href: '/feedback',
        icon: FeedbackOutlinedIcon
      },
      {
        title: 'Quản lý ứng dụng cài đặt',
        role: 'get_application',
        href: '/application',
        icon: AppsOutlinedIcon
      }
      //!Bravigo end-------------------------------------------
    ]
  }
];
