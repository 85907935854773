import { combineReducers } from 'redux';
import { roleReducer } from './roleReducer';
import { userReducer } from './userReducer';
import { agencyReducer } from './agencyReducer';
import { customerReducer } from './customerReducer';
import { licenseReducer } from './licenseReducer';
import { guaranteeReducer } from './guaranteeReducer';
import { feedbackReducer } from './feedbackReducer';
import { provinceReducer } from './provinceReducer';
import dashboardReducer from './dashboardReducer';
import applicationReducer from './applicationReducer';

const rootReducers = combineReducers({
  userReducer,
  roleReducer,
  agencyReducer,
  customerReducer,
  licenseReducer,
  guaranteeReducer,
  feedbackReducer,
  provinceReducer,
  dashboardReducer,
  applicationReducer
});
export default rootReducers;
