import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DELETE, GET, PATCH, POST } from 'src/Service/ServiceBase';
import ServiceURL from 'src/Service/ServiceURL';
import TypeActions from '../TypeActions';

export function* getListAgency(data) {
  const url = ServiceURL.Agency + '?' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LIST_AGENCY_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_LIST_AGENCY_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LIST_AGENCY_FAILED,
      error: error.response.data.message
    });
  }
}

export function* getAgency(data) {
  const url = ServiceURL.Agency + '/' + data.params;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_AGENCY_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_AGENCY_SUCCESS,
        data: res.data
      });
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_AGENCY_FAILED,
      error: error.response.data.message
    });
  }
}

export function* createAgency(data) {
  const url = ServiceURL.Agency;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_AGENCY_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.CREATE_AGENCY_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_AGENCY_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* editAgency(data) {
  const url = ServiceURL.Agency + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EDIT_AGENCY_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.EDIT_AGENCY_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.EDIT_AGENCY_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}
export function* deleteAgency(data) {
  const url = ServiceURL.Agency + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.DELETE_AGENCY_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.DELETE_AGENCY_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.DELETE_AGENCY_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* getStatistics(data) {
  const url = ServiceURL.Statistics + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_STATISTICS_AGENCY_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_STATISTICS_AGENCY_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_STATISTICS_AGENCY_FAILED,
      error: error.response.data.message
    });
  }
}

export function* getStatistic(data) {
  const url = ServiceURL.Statistics + '/' + data.params;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_STATISTIC_AGENCY_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_STATISTICS_AGENCY_SUCCESS,
        data: res.data
      });
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_STATISTIC_AGENCY_FAILED,
      error: error.response.data.message
    });
  }
}

export function* getAgencyZeroActiveLicense(data) {
  const url = ServiceURL.AgencyZeroActiveLicense + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_AGENCY_ZERO_ACTIVE_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_AGENCY_ZERO_ACTIVE_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_AGENCY_ZERO_ACTIVE_FAILED,
      error: error.response.data.message
    });
  }
}

export default function* agencySaga() {
  yield takeLatest(TypeActions.GET_LIST_AGENCY_REQUEST, getListAgency);
  yield takeLatest(TypeActions.GET_AGENCY_REQUEST, getAgency);
  yield takeLatest(TypeActions.CREATE_AGENCY_REQUEST, createAgency);
  yield takeLatest(TypeActions.EDIT_AGENCY_REQUEST, editAgency);
  yield takeLatest(TypeActions.DELETE_AGENCY_REQUEST, deleteAgency);
  yield takeLatest(TypeActions.GET_STATISTICS_AGENCY_REQUEST, getStatistics);
  yield takeLatest(TypeActions.GET_STATISTIC_AGENCY_REQUEST, getStatistic);
  yield takeLatest(
    TypeActions.GET_AGENCY_ZERO_ACTIVE_REQUEST,
    getAgencyZeroActiveLicense
  );
}
