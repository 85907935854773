import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DELETE, GET, PATCH, POST } from 'src/Service/ServiceBase';
import ServiceURL from 'src/Service/ServiceURL';
import TypeActions from '../TypeActions';

export function* getListFeedback(data) {
  const url = ServiceURL.Feedback + '?' + data.params;
  // const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LIST_FEEDBACK_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_LIST_FEEDBACK_SUCCESS,
        data: res.data
      });
      // callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LIST_FEEDBACK_FAILED,
      error: error.response.data.message
    });
  }
}

export function* getFeedback(data) {
  const url = ServiceURL.Feedback + '/' + data.params;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_FEEDBACK_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_FEEDBACK_SUCCESS,
        data: res.data
      });
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_FEEDBACK_FAILED,
      error: error.response.data.message
    });
  }
}

export function* createFeedback(data) {
  const url = ServiceURL.Feedback;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_FEEDBACK_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.CREATE_FEEDBACK_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_FEEDBACK_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* editFeedback(data) {
  const url = ServiceURL.Feedback + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EDIT_FEEDBACK_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.EDIT_FEEDBACK_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.EDIT_FEEDBACK_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}
export function* deleteFeedback(data) {
  const url = ServiceURL.Feedback + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.DELETE_FEEDBACK_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.DELETE_FEEDBACK_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.DELETE_FEEDBACK_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

// export function* viewedFeedback(data) {
//   yield put({
//     type: TypeActions.VIEWED_FEEDBACK_SUCCESS,
//     id: data.id
//   });
// }

export default function* feedbackSaga() {
  yield takeLatest(TypeActions.GET_LIST_FEEDBACK_REQUEST, getListFeedback);
  yield takeLatest(TypeActions.GET_FEEDBACK_REQUEST, getFeedback);
  yield takeLatest(TypeActions.CREATE_FEEDBACK_REQUEST, createFeedback);
  yield takeLatest(TypeActions.EDIT_FEEDBACK_REQUEST, editFeedback);
  yield takeLatest(TypeActions.DELETE_FEEDBACK_REQUEST, deleteFeedback);
  // yield takeLatest(TypeActions.VIEWED_FEEDBACK_REQUEST, viewedFeedback);
}
