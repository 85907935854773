import TypeActions from '../TypeActions';

const initialState = {
  listFeedback: [],
  feedbackById: {},
  isGetting: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  isGetFeedbackById: false,
  error: ''
};

export const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    //!Get list feedback
    case TypeActions.GET_LIST_FEEDBACK_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_LIST_FEEDBACK_SUCCESS:
      return {
        ...state,
        listFeedback: action.data,
        isGetting: false
      };
    case TypeActions.GET_LIST_FEEDBACK_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Get feedback
    case TypeActions.GET_FEEDBACK_REQUEST:
      return {
        ...state,
        isGetFeedbackById: true
      };
    case TypeActions.GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackById: action.data,
        isGetFeedbackById: false
      };
    case TypeActions.GET_FEEDBACK_FAILED:
      return {
        ...state,
        error: action.error,
        isGetFeedbackById: false
      };
    //!Create feedback
    case TypeActions.CREATE_FEEDBACK_REQUEST:
      return {
        ...state,
        isCreating: true
      };
    case TypeActions.CREATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        isCreating: false
      };
    case TypeActions.CREATE_FEEDBACK_FAILED:
      return {
        ...state,
        error: action.error,
        isCreating: false
      };
    //!Edit feedback
    case TypeActions.EDIT_FEEDBACK_REQUEST:
      return {
        ...state,
        isEditing: true
      };
    case TypeActions.EDIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        isEditing: false
      };
    case TypeActions.EDIT_FEEDBACK_FAILED:
      return {
        ...state,
        error: action.error,
        isEditing: false
      };
    //!Delete feedback
    case TypeActions.DELETE_FEEDBACK_REQUEST:
      return {
        ...state,
        isDeleting: true
      };
    case TypeActions.DELETE_FEEDBACK_SUCCESS:
      return {
        ...state,
        isDeleting: false
      };
    case TypeActions.DELETE_FEEDBACK_FAILED:
      return {
        ...state,
        error: action.error,
        isDeleting: false
      };
    // Viewed feedback
    case TypeActions.VIEWED_FEEDBACK_SUCCESS:
      const index = state.listFeedback.results.findIndex(
        item => item.id === action.id
      );
      return {
        ...state,
        listFeedback: {
          ...state.listFeedback,
          results: [
            ...state.listFeedback.results.slice(0, index),
            {
              ...state.listFeedback.results[index],
              isRead: true
            },
            ...state.listFeedback.results.slice(index + 1)
          ]
        }
      };
    //!Default
    default:
      return {
        ...state
      };
  }
};

export default feedbackReducer;
