// const { default: TypeActions } = require("Redux/TypeActions");
import TypeActions from 'src/Redux/TypeActions';

export const login = (body, callback) => {
  return {
    type: TypeActions.LOGIN_REQUEST,
    body,
    callback
  };
};
export const logout = body => {
  return {
    type: TypeActions.LOGOUT_REQUEST,
    body
  };
};

export const getUsers = (params, callback) => {
  return {
    type: TypeActions.GET_USERS_REQUEST,
    params,
    callback
  };
};
export const getUsersInfor = params => {
  return {
    type: TypeActions.GET_USERS_INFOR_REQUEST,
    params
  };
};

export const changePassword = (body, params, callback) => {
  return {
    type: TypeActions.CHANGE_PASSWORD_USERS_REQUEST,
    body,
    params,
    callback
  };
};
export const forgotPassword = (body, callback) => {
  return {
    type: TypeActions.FORGOT_PASSWORD_USERS_REQUEST,
    body,
    callback
  };
};
export const resetPassword = (body, params, callback) => {
  return {
    type: TypeActions.RESET_PASSWORD_USERS_REQUEST,
    body,
    params,
    callback
  };
};

export const deleteUsers = (params, callback) => {
  return {
    type: TypeActions.DELETE_USERS_REQUEST,
    params,
    callback
  };
};

export const getListUsers = (params, callback) => {
  return {
    type: TypeActions.GET_LIST_USERS_REQUEST,
    params,
    callback
  };
};

export const createUsers = (body, callback) => {
  return {
    type: TypeActions.CREATE_USERS_REQUEST,
    body,
    callback
  };
};

export const editUsers = (body, params, callback) => {
  return {
    type: TypeActions.EDIT_USERS_REQUEST,
    body,
    params,
    callback
  };
};

export const uploadImage = (body, callback) => {
  return {
    type: TypeActions.UPLOAD_IMAGE_REQUEST,
    body,
    callback
  };
};

export const setPassForUser = (body, callback) => {
  return {
    type: TypeActions.SET_PASS_USER_REQUEST,
    body,
    callback
  };
};

export default {
  login,
  getUsers,
  getUsersInfor,
  getListUsers,
  createUsers,
  editUsers,
  deleteUsers,
  uploadImage,
  changePassword,
  forgotPassword,
  logout,
  resetPassword,
  setPassForUser
};
