import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { GET } from 'src/Service/ServiceBase';
import ServiceURL from 'src/Service/ServiceURL';
import TypeActions from '../TypeActions';

export function* getListProvince(data) {
  const url = ServiceURL.Province + '?' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LIST_PROVINCE_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_LIST_PROVINCE_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LIST_PROVINCE_FAILED,
      error: error.response.data.message
    });
  }
}

export function* getListDistrict(data) {
  const url = ServiceURL.District + '?' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LIST_DISTRICT_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_LIST_DISTRICT_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LIST_DISTRICT_FAILED,
      error: error.response.data.message
    });
  }
}

export function* getListWard(data) {
  const url = ServiceURL.Ward + '?' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LIST_WARD_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_LIST_WARD_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LIST_WARD_FAILED,
      error: error.response.data.message
    });
  }
}

export default function* provinceSaga() {
  yield takeLatest(TypeActions.GET_LIST_PROVINCE_REQUEST, getListProvince);
  yield takeLatest(TypeActions.GET_LIST_DISTRICT_REQUEST, getListDistrict);
  yield takeLatest(TypeActions.GET_LIST_WARD_REQUEST, getListWard);
}
