import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DELETE, GET, PATCH, POST } from 'src/Service/ServiceBase';
import ServiceURL from 'src/Service/ServiceURL';
import TypeActions from '../TypeActions';

export function* getListDevice(data) {
  const url = ServiceURL.Device + '?' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LIST_DEVICE_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_LIST_DEVICE_SUCCESS,
        data: res.data
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LIST_DEVICE_FAILED,
      error: error.response.data.message
    });
  }
}

export function* getListDivicesReceive(data) {
  const url = ServiceURL.Device + '?' + data.params;
  // const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LIST_DEVICE_RECEIVE_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_LIST_DEVICE_RECEIVE_SUCCESS,
        data: res.data
      });
      // callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LIST_DEVICE_RECEIVE_FAILED,
      error: error.response.data.message
    });
  }
}

export function* getListDivicesHistory(data) {
  const url = 'deviceHistorys?' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LIST_DEVICE_HISTORY_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_LIST_DEVICE_HISTORY_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LIST_DEVICE_HISTORY_FAILED,
      error: error.response.data.message
    });
  }
}

export function* getDevice(data) {
  const url = ServiceURL.Device + '/' + data.params;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_DEVICE_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_DEVICE_SUCCESS,
        data: res.data
      });
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_DEVICE_FAILED,
      error: error.response.data.message
    });
  }
}

export function* createDevice(data) {
  const url = ServiceURL.Device;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_DEVICE_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.CREATE_DEVICE_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_DEVICE_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* receiveDevice(data) {
  const callback = data.callback;
  const url = ServiceURL.Device + '/receiveGuaranteeDevice';
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.RECEIVE_DEVICE_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.RECEIVE_DEVICE_SUCCESS
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.RECEIVE_DEVICE_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* convertDevice(data) {
  const callback = data.callback;
  const url = ServiceURL.Device + '/convertGuaranteeDevice/' + data.params;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CONVERT_DEVICE_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.CONVERT_DEVICE_SUCCESS
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.CONVERT_DEVICE_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* editDevice(data) {
  const url = ServiceURL.Device + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EDIT_DEVICE_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.EDIT_DEVICE_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.EDIT_DEVICE_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}
export function* unReceiveGuaranteeDevice(data) {
  const url =
    ServiceURL.Device +
    '/' +
    ServiceURL.UnReceiveGuaranteeDevice +
    '/' +
    data.params;
  const callback = data.callback;
  try {
    const res = yield call(POST, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.DELETE_DEVICE_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.DELETE_DEVICE_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.DELETE_DEVICE_FAILED,
      error: error
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* updateSubIdDevice(data) {
  const url = ServiceURL.Device + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.UPDATE_SUB_ID_DEVICE_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.UPDATE_SUB_ID_DEVICE_SUCCESS
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.UPDATE_SUB_ID_DEVICE_FAILED,
      error: error?.response?.data?.message
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export default function* customerSaga() {
  yield takeLatest(TypeActions.GET_LIST_DEVICE_REQUEST, getListDevice);
  yield takeLatest(TypeActions.GET_DEVICE_REQUEST, getDevice);
  yield takeLatest(TypeActions.CREATE_DEVICE_REQUEST, createDevice);
  yield takeLatest(TypeActions.RECEIVE_DEVICE_REQUEST, receiveDevice);
  yield takeLatest(TypeActions.CONVERT_DEVICE_REQUEST, convertDevice);
  yield takeLatest(TypeActions.EDIT_DEVICE_REQUEST, editDevice);
  yield takeLatest(TypeActions.DELETE_DEVICE_REQUEST, unReceiveGuaranteeDevice);
  yield takeLatest(
    TypeActions.GET_LIST_DEVICE_RECEIVE_REQUEST,
    getListDivicesReceive
  );
  yield takeLatest(
    TypeActions.GET_LIST_DEVICE_HISTORY_REQUEST,
    getListDivicesHistory
  );
  yield takeLatest(TypeActions.UPDATE_SUB_ID_DEVICE_REQUEST, updateSubIdDevice);
}
