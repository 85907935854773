import TypeActions from '../TypeActions';

export const getListCustomer = params => {
  return {
    type: TypeActions.GET_LIST_CUSTOMER_REQUEST,
    params
    // callback
  };
};
export const getCustomer = params => {
  return {
    type: TypeActions.GET_CUSTOMER_REQUEST,
    params
  };
};

export const createCustomer = (body, callback) => {
  return {
    type: TypeActions.CREATE_CUSTOMER_REQUEST,
    body,
    callback
  };
};
export const editCustomer = (body, params, callback) => {
  return {
    type: TypeActions.EDIT_CUSTOMER_REQUEST,
    body,
    params,
    callback
  };
};
export const deleteCustomer = (params, callback) => {
  return {
    type: TypeActions.DELETE_CUSTOMER_REQUEST,
    params,
    callback
  };
};

export const resetPinCustomer = (params, callback) => {
  return {
    type: TypeActions.RESET_PIN_CUSTOMER_REQUEST,
    params,
    callback
  };
};

export default {
  getListCustomer,
  createCustomer,
  editCustomer,
  deleteCustomer,
  getCustomer,
  resetPinCustomer
};
