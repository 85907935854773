import TypeActions from '../TypeActions';

export const getListAgency = (params, callback) => {
  return {
    type: TypeActions.GET_LIST_AGENCY_REQUEST,
    params,
    callback
  };
};
export const getAgency = params => {
  return {
    type: TypeActions.GET_AGENCY_REQUEST,
    params
  };
};

export const createAgency = (body, callback) => {
  return {
    type: TypeActions.CREATE_AGENCY_REQUEST,
    body,
    callback
  };
};
export const editAgency = (body, params, callback) => {
  return {
    type: TypeActions.EDIT_AGENCY_REQUEST,
    body,
    params,
    callback
  };
};
export const deleteAgency = (params, callback) => {
  return {
    type: TypeActions.DELETE_AGENCY_REQUEST,
    params,
    callback
  };
};

export const getStatistics = (query, callback) => {
  return {
    type: TypeActions.GET_STATISTICS_AGENCY_REQUEST,
    query,
    callback
  };
};
export const getStatistic = (params, callback) => {
  return {
    type: TypeActions.GET_STATISTIC_AGENCY_REQUEST,
    params,
    callback
  };
};
export const getAgencyZeroActiveLicense = (query, callback) => {
  return {
    type: TypeActions.GET_AGENCY_ZERO_ACTIVE_REQUEST,
    query,
    callback
  };
};
export default {
  getListAgency,
  createAgency,
  editAgency,
  deleteAgency,
  getAgency,
  getStatistic,
  getStatistics,
  getAgencyZeroActiveLicense
};
