import TypeActions from '../TypeActions';

export const getListRole = (params, callback) => {
  return {
    type: TypeActions.GET_LIST_ROLE_REQUEST,
    params,
    callback
  };
};
export const getRole = params => {
  return {
    type: TypeActions.GET_ROLE_REQUEST,
    params
  };
};
export const getRolePermission = () => {
  return {
    type: TypeActions.GET_ROLE_PERMISSION_REQUEST
  };
};
export const createRole = (body, callback) => {
  return {
    type: TypeActions.CREATE_ROLE_REQUEST,
    body,
    callback
  };
};
export const editRole = (body, params, callback) => {
  return {
    type: TypeActions.EDIT_ROLE_REQUEST,
    body,
    params,
    callback
  };
};
export const deleteRole = (params, callback) => {
  return {
    type: TypeActions.DELETE_ROLE_REQUEST,
    params,
    callback
  };
};

export default {
  getListRole,
  createRole,
  editRole,
  deleteRole,
  getRolePermission,
  getRole
};
