import { AppBar, Grid, Hidden, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { toUpper } from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  firstTextTopbar: {
    fontFamily: 'roboto',
    color: '#FFFF',
    fontSize: '20px',
    fontWeight: 'bold'
  },
  secondTextTopbar: {
    fontFamily: 'roboto',
    marginTop: '5px',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: '700'
  }
}));

function Topbar({ className, ...rest }) {
  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      // color="primary"
      style={{
        backgroundColor: '#B62A2A'
      }}
    >
      <Toolbar>
        {/* <RouterLink to="/">
          <img alt="Logo" src="/images/logos/logo--white.svg" />
        </RouterLink> */}
        <Grid container>
          <Grid
            item
            lg={2}
            sm={11}
            xs={10}
            // style={{ display: 'flex', justifyContent: 'center' }}
          >
            <RouterLink to="/">
              <img alt="Logo" src="/images/logos/logoBravigo1.png" />
            </RouterLink>
          </Grid>
          <Hidden mdDown>
            <Grid item lg={10} sm={4} xs={12} style={{ margin: 'auto' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  textAlign: 'center',
                  paddingLeft: '50px'
                }}
              >
                <div className={classes.firstTextTopbar}>
                  <RouterLink to="/" style={{ color: 'white' }}>
                    {toUpper('HỆ THỐNG QUẢN LÝ ỨNG DỤNG BRAVIGO')}
                  </RouterLink>
                </div>
                {/* <div className={classes.secondTextTopbar}>
                  {toUpper('Hệ thống quản lý ươm tạo và chọn giống')}
                </div> */}
              </div>
            </Grid>
          </Hidden>
          {/* <Grid
            item
            lg={2}
            sm={1}
            xs={2}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          ></Grid> */}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
