import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DELETE, GET, PATCH, POST } from 'src/Service/ServiceBase';
import ServiceURL from 'src/Service/ServiceURL';
import TypeActions from '../TypeActions';

export function* getListCustomer(data) {
  const url = ServiceURL.Customer + '?' + data.params;
  // const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LIST_CUSTOMER_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_LIST_CUSTOMER_SUCCESS,
        data: res.data
      });
      // callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LIST_CUSTOMER_FAILED,
      error: error.response.data.message
    });
  }
}

export function* getCustomer(data) {
  const url = ServiceURL.Customer + '/' + data.params;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_CUSTOMER_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_CUSTOMER_SUCCESS,
        data: res.data
      });
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_CUSTOMER_FAILED,
      error: error.response.data.message
    });
  }
}

export function* createCustomer(data) {
  const url = ServiceURL.Customer;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_CUSTOMER_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.CREATE_CUSTOMER_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_CUSTOMER_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* editCustomer(data) {
  const url = ServiceURL.Customer + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EDIT_CUSTOMER_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.EDIT_CUSTOMER_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.EDIT_CUSTOMER_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}
export function* deleteCustomer(data) {
  const url = ServiceURL.Customer + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.DELETE_CUSTOMER_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.DELETE_CUSTOMER_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.DELETE_CUSTOMER_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* resetPinCustomer(data) {
  const url = ServiceURL.Customer + '/resetPin/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(POST, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.RESET_PIN_CUSTOMER_FAILED,
        error: res?.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.RESET_PIN_CUSTOMER_SUCCESS
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.RESET_PIN_CUSTOMER_FAILED,
      error: error?.response?.data?.message
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export default function* customerSaga() {
  yield takeLatest(TypeActions.GET_LIST_CUSTOMER_REQUEST, getListCustomer);
  yield takeLatest(TypeActions.GET_CUSTOMER_REQUEST, getCustomer);
  yield takeLatest(TypeActions.CREATE_CUSTOMER_REQUEST, createCustomer);
  yield takeLatest(TypeActions.EDIT_CUSTOMER_REQUEST, editCustomer);
  yield takeLatest(TypeActions.DELETE_CUSTOMER_REQUEST, deleteCustomer);
  yield takeLatest(TypeActions.RESET_PIN_CUSTOMER_REQUEST, resetPinCustomer);
}
