import TypeActions from '../TypeActions';

export const getListLicense = (params, callback) => {
  return {
    type: TypeActions.GET_LIST_LICENSE_REQUEST,
    params,
    callback
  };
};
export const getLicense = params => {
  return {
    type: TypeActions.GET_LICENSE_REQUEST,
    params
  };
};

export const createLicense = (body, callback) => {
  return {
    type: TypeActions.CREATE_LICENSE_REQUEST,
    body,
    callback
  };
};
export const editLicense = (body, params, callback) => {
  return {
    type: TypeActions.EDIT_LICENSE_REQUEST,
    body,
    params,
    callback
  };
};
export const deleteLicense = (params, callback) => {
  return {
    type: TypeActions.DELETE_LICENSE_REQUEST,
    params,
    callback
  };
};

export const resetLicense = (body, params, callback) => {
  return {
    type: TypeActions.RESET_LICENSE_REQUEST,
    params,
    callback
  };
};

export default {
  getListLicense,
  createLicense,
  editLicense,
  deleteLicense,
  getLicense,
  resetLicense
};
