import TypeActions from '../TypeActions';

const initialState = {
  listCustomer: [],
  customerById: {},
  isGetting: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  isResetPin: false,
  error: ''
};

export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    //!Get list customer
    case TypeActions.GET_LIST_CUSTOMER_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_LIST_CUSTOMER_SUCCESS:
      return {
        ...state,
        listCustomer: action.data,
        isGetting: false
      };
    case TypeActions.GET_LIST_CUSTOMER_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Get customer
    case TypeActions.GET_CUSTOMER_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerById: action.data,
        isGetting: false
      };
    case TypeActions.GET_CUSTOMER_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Create customer
    case TypeActions.CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        isCreating: true
      };
    case TypeActions.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        isCreating: false
      };
    case TypeActions.CREATE_CUSTOMER_FAILED:
      return {
        ...state,
        error: action.error,
        isCreating: false
      };
    //!Edit customer
    case TypeActions.EDIT_CUSTOMER_REQUEST:
      return {
        ...state,
        isEditing: true
      };
    case TypeActions.EDIT_CUSTOMER_SUCCESS:
      return {
        ...state,
        isEditing: false
      };
    case TypeActions.EDIT_CUSTOMER_FAILED:
      return {
        ...state,
        error: action.error,
        isEditing: false
      };
    //!Delete customer
    case TypeActions.DELETE_CUSTOMER_REQUEST:
      return {
        ...state,
        isDeleting: true
      };
    case TypeActions.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        isDeleting: false
      };
    case TypeActions.DELETE_CUSTOMER_FAILED:
      return {
        ...state,
        error: action.error,
        isDeleting: false
      };
    //!Reset pin
    case TypeActions.RESET_PIN_CUSTOMER_REQUEST:
      return {
        ...state,
        isResetPin: true
      };
    case TypeActions.RESET_PIN_CUSTOMER_SUCCESS:
      return {
        ...state,
        isResetPin: false
      };
    case TypeActions.RESET_PIN_CUSTOMER_FAILED:
      return {
        ...state,
        error: action.error,
        isResetPin: false
      };
    //!Default
    default:
      return {
        ...state
      };
  }
};

export default customerReducer;
