import TypeActions from '../TypeActions';

const dataDemo = {
  page: 1,
  limit: 10,
  results: [
    {
      name: 'Ứng dụng Bravigo',
      releaseDate: '2023-08-03T02:19:00.000Z',
      applicationType: 'Tiện ích',
      description: 'Nooooooooooo'
    }
  ],
  totalPages: 1,
  totalResults: 1
};

const initialState = {
  applications: dataDemo,
  application: null,
  isGetting: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  isImageUploading: false,
  isFileUploading: false,
  error: ''
};

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    //!Get list application
    case TypeActions.GET_APPLICATIONS_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        applications: action.data,
        isGetting: false
      };
    case TypeActions.GET_APPLICATIONS_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Get application
    case TypeActions.GET_APPLICATION_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_APPLICATION_SUCCESS:
      return {
        ...state,
        application: action.data,
        isGetting: false
      };
    case TypeActions.GET_APPLICATION_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Create application
    case TypeActions.CREATE_APPLICATION_REQUEST:
      return {
        ...state,
        isCreating: true
      };
    case TypeActions.CREATE_APPLICATION_SUCCESS:
      return {
        ...state,
        isCreating: false
      };
    case TypeActions.CREATE_APPLICATION_FAILED:
      return {
        ...state,
        error: action.error,
        isCreating: false
      };
    //!Edit application
    case TypeActions.UPDATE_APPLICATION_REQUEST:
      return {
        ...state,
        isEditing: true
      };
    case TypeActions.UPDATE_APPLICATION_SUCCESS:
      return {
        ...state,
        isEditing: false
      };
    case TypeActions.UPDATE_APPLICATION_FAILED:
      return {
        ...state,
        error: action.error,
        isEditing: false
      };
    //!Delete application
    case TypeActions.DELETE_APPLICATION_REQUEST:
      return {
        ...state,
        isDeleting: true
      };
    case TypeActions.DELETE_APPLICATION_SUCCESS:
      return {
        ...state,
        isDeleting: false
      };
    case TypeActions.DELETE_APPLICATION_FAILED:
      return {
        ...state,
        error: action.error,
        isDeleting: false
      };

    case TypeActions.UPLOAD_APPLICATION_IMAGE_REQUEST:
      return {
        ...state,
        isImageUploading: true
      };
    case TypeActions.UPLOAD_APPLICATION_IMAGE_SUCCESS:
      return {
        ...state,
        isImageUploading: false
      };
    case TypeActions.UPLOAD_APPLICATION_IMAGE_FAILED:
      return {
        ...state,
        error: action.error,
        isImageUploading: false
      };

    case TypeActions.UPLOAD_APPLICATION_FILE_REQUEST:
      return {
        ...state,
        isFileUploading: true
      };
    case TypeActions.UPLOAD_APPLICATION_FILE_SUCCESS:
      return {
        ...state,
        isFileUploading: false
      };
    case TypeActions.UPLOAD_APPLICATION_FILE_FAILED:
      return {
        ...state,
        error: action.error,
        isFileUploading: false
      };

    //!Default
    default:
      return {
        ...state
      };
  }
};

export default applicationReducer;
