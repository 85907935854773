export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL_IMAGE = process.env.REACT_APP_BASE_URL_IMAGE;

export const Login = 'auth/login/';
export const Logout = 'auth/logout/';
export const User = 'users';
export const ChangePassword = 'users/change-password';
export const ForgotPassword = 'auth/forgot-password';
export const ResetPassword = 'auth/reset-password';
export const Role = 'roles';
export const RolePermission = 'permissions';
export const setPassUser = 'users/set-password';
export const Agency = 'agencys';
export const Customer = 'customers';
export const License = 'licenses';
export const Guarantee = 'guarantees';
export const Device = 'devices';
export const Feedback = 'feedbacks';
export const UnReceiveGuaranteeDevice = 'unReceiveGuaranteeDevice';
export const Province = 'provinces';
export const District = 'districts';
export const Ward = 'awards';
export const Dashboard = 'dashboards';
export const Statistics = 'statisticalReports/agencyActiveLicense';
export const AgencyZeroActiveLicense =
  'statisticalReports/agencyZeroActiveLicense';
export const UploadImageSeedling = 'uploads/seedlingImage';
export const Application = 'applications';

export default {
  BASE_URL,
  Login,
  User,
  Logout,
  ChangePassword,
  ForgotPassword,
  ResetPassword,
  Role,
  RolePermission,
  setPassUser,
  Customer,
  Agency,
  License,
  Guarantee,
  Device,
  Feedback,
  UnReceiveGuaranteeDevice,
  Province,
  District,
  Ward,
  Dashboard,
  Statistics,
  AgencyZeroActiveLicense,
  Application
};
