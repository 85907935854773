import TypeActions from '../TypeActions';

const initialState = {
  listProvince: [],
  listDistrict: [],
  listWard: [],
  isGetting: false,
  error: ''
};

export const provinceReducer = (state = initialState, action) => {
  switch (action.type) {
    //!Get list province
    case TypeActions.GET_LIST_PROVINCE_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_LIST_PROVINCE_SUCCESS:
      return {
        ...state,
        listProvince: action.data,
        isGetting: false
      };
    case TypeActions.GET_LIST_PROVINCE_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Get district
    case TypeActions.GET_LIST_DISTRICT_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_LIST_DISTRICT_SUCCESS:
      return {
        ...state,
        listDistrict: action.data,
        isGetting: false
      };
    case TypeActions.GET_LIST_DISTRICT_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Create ward
    case TypeActions.GET_LIST_WARD_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_LIST_WARD_SUCCESS:
      return {
        ...state,
        listWard: action.data,
        isGetting: false
      };
    case TypeActions.GET_LIST_WARD_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };

    //!Default
    default:
      return {
        ...state
      };
  }
};

export default provinceReducer;
