import TypeActions from '../TypeActions';

export const getListDevice = (params, callback = {}) => {
  return {
    type: TypeActions.GET_LIST_DEVICE_REQUEST,
    params,
    callback
  };
};

export const getListDivicesReceive = params => {
  return {
    type: TypeActions.GET_LIST_DEVICE_RECEIVE_REQUEST,
    params
    // callback
  };
};
export const getListDivicesHistory = params => {
  return {
    type: TypeActions.GET_LIST_DEVICE_HISTORY_REQUEST,
    params
    // callback
  };
};

export const receiveDevice = (body, callback) => {
  return {
    type: TypeActions.RECEIVE_DEVICE_REQUEST,
    body,
    callback
  };
};

export const convertDevice = (body, params, callback) => {
  return {
    type: TypeActions.CONVERT_DEVICE_REQUEST,
    body,
    params,
    callback
  };
};

export const getDevice = params => {
  return {
    type: TypeActions.GET_DEVICE_REQUEST,
    params
  };
};

export const createDevice = (body, callback) => {
  return {
    type: TypeActions.CREATE_DEVICE_REQUEST,
    body,
    callback
  };
};

export const editDevice = (body, params, callback) => {
  return {
    type: TypeActions.EDIT_DEVICE_REQUEST,
    body,
    params,
    callback
  };
};

export const unReceiveGuaranteeDevice = (params, callback) => {
  return {
    type: TypeActions.DELETE_DEVICE_REQUEST,
    params,
    callback
  };
};

export const updateSubIdDevice = (body, params, callback) => {
  return {
    type: TypeActions.UPDATE_SUB_ID_DEVICE_REQUEST,
    body,
    params,
    callback
  };
};

export default {
  getListDevice,
  createDevice,
  editDevice,
  unReceiveGuaranteeDevice,
  getDevice,
  getListDivicesReceive,
  receiveDevice,
  convertDevice,
  getListDivicesHistory,
  updateSubIdDevice
};
