import TypeActions from 'src/Redux/TypeActions';

const initialState = {
  data: {},
  listData: [],
  userByID: {},
  userInfor: {},
  error: '',
  imageURL: '',
  errorLogin: '',
  isGetting: false,
  isLogging: false,
  isCreating: false,
  isEditting: false,
  isDeleting: false,
  isUploading: false,
  isLoading: false,
  isSetPass: false
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    //!Get User
    case TypeActions.GET_USERS_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_USERS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        userByID: action.data
      };
    case TypeActions.GET_USERS_FAILED:
      return {
        ...state,
        isGetting: false,
        error: action.error
      };
    //!Get User Infor
    case TypeActions.GET_USERS_INFOR_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_USERS_INFOR_SUCCESS:
      return {
        ...state,
        isGetting: false,
        userInfor: action.data
      };
    case TypeActions.GET_USERS_INFOR_FAILED:
      return {
        ...state,
        isGetting: false,
        error: action.error
      };
    //!Delete User
    case TypeActions.DELETE_USERS_REQUEST:
      return {
        ...state,
        isDeleting: true
      };
    case TypeActions.DELETE_USERS_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        error: ''
      };
    case TypeActions.DELETE_USERS_FAILED:
      return {
        ...state,
        isDeleting: false,
        error: action.error
      };
    //!Get List User
    case TypeActions.GET_LIST_USERS_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_LIST_USERS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        listData: action.data,
        error: ''
      };
    case TypeActions.GET_LIST_USERS_FAILED:
      return {
        ...state,
        isGetting: false,
        error: action.error
      };
    //!Create User
    case TypeActions.CREATE_USERS_REQUEST:
      return {
        ...state,
        isCreating: true
      };
    case TypeActions.CREATE_USERS_SUCCESS:
      return {
        ...state,
        isCreating: false,
        error: ''
      };
    case TypeActions.CREATE_USERS_FAILED:
      return {
        ...state,
        isCreating: false,
        error: action.error
      };
    //!Edit User
    case TypeActions.EDIT_USERS_REQUEST:
      return {
        ...state,
        isEditting: true
      };
    case TypeActions.EDIT_USERS_SUCCESS:
      return {
        ...state,
        isEditting: false,
        error: ''
      };
    case TypeActions.EDIT_USERS_FAILED:
      return {
        ...state,
        isEditting: false,
        error: action.error
      };
    //!Login
    case TypeActions.LOGIN_REQUEST:
      return {
        ...state,
        isLogging: true
      };
    case TypeActions.LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        isLogging: false,
        errorLogin: '',
        data: action.data,
        userInfor: action.data
      };
    case TypeActions.LOGIN_REQUEST_FAILED:
      return {
        ...state,
        isLogging: false,
        errorLogin: action.error
      };
    //!Change password
    case TypeActions.CHANGE_PASSWORD_USERS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case TypeActions.CHANGE_PASSWORD_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case TypeActions.CHANGE_PASSWORD_USERS_FAILED:
      return {
        ...state,
        isLoading: false,
        errorLogin: action.error
      };
    //!Forgot password
    case TypeActions.FORGOT_PASSWORD_USERS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case TypeActions.FORGOT_PASSWORD_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case TypeActions.FORGOT_PASSWORD_USERS_FAILED:
      return {
        ...state,
        isLoading: false,
        errorLogin: action.error
      };
    //!Reset password
    case TypeActions.RESET_PASSWORD_USERS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case TypeActions.RESET_PASSWORD_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case TypeActions.RESET_PASSWORD_USERS_FAILED:
      return {
        ...state,
        isLoading: false,
        errorLogin: action.error
      };
    //!Upload
    case TypeActions.UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        isUploading: true
      };
    case TypeActions.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        isUploading: false,
        imageURL: action.data
      };
    case TypeActions.UPLOAD_IMAGE_FAILED:
      return {
        ...state,
        isUploading: false,
        errorLogin: action.error
      };
    //!Logout
    case TypeActions.LOGOUT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case TypeActions.LOGOUT_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case TypeActions.LOGOUT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        errorLogin: action.error
      };
    case TypeActions.SET_PASS_USER_REQUEST:
      return {
        ...state,
        isSetPass: true
      };
    case TypeActions.SET_PASS_USER_SUCCESS:
      return {
        ...state,
        isSetPass: false
      };
    case TypeActions.SET_PASS_USER_FAILED:
      return {
        ...state,
        isSetPass: false
      };
    //!Default
    default:
      return {
        ...state
      };
  }
};
export default userReducer;
