/* eslint-disable react/no-multi-comp */
import {
  Avatar,
  Badge,
  colors,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Link,
  List,
  Menu,
  MenuItem
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoreIcon from '@material-ui/icons/MoreVert';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import NavItem from 'src/components/NavItem';
import Popup from 'src/components/Popup/index';
import ChangePassword from 'src/containers/ChangePassword';
import userActions from 'src/Redux/Actions/userActions';
import navConfig from './navConfig';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  badgeDot: {
    height: 9,
    minWidth: 9
  },
  onlineBadge: {
    backgroundColor: colors.green[600]
  },
  awayBadge: {
    backgroundColor: colors.orange[600]
  },
  busyBadge: {
    backgroundColor: colors.red[600]
  },
  offlineBadge: {
    backgroundColor: colors.grey[300]
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40
  },
  details: {
    marginLeft: theme.spacing(2)
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200]
  },
  button: {
    // margin: theme.spacing(1),
    // color: 'transparent',
    backgroundColor: 'transparent',
    '&:hover, &:focus, &:active, &:visited': {
      backgroundColor: 'transparent',
      background: 'transparent',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }
}));

function renderNavItems({
  // eslint-disable-next-line react/prop-types
  items,
  subheader,
  key,
  permission,
  ...rest
}) {
  return (
    <List key={key}>
      {/* {subheader && <ListSubheader disableSticky>{subheader}</ListSubheader>} */}
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) => reduceChildRoutes({ acc, item, permission, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, permission, depth = 0 }) {
  if (
    (permission !== undefined && permission.indexOf(item.role) !== -1) ||
    item.role === ''
  ) {
    if (item.items) {
      const open = matchPath(pathname, {
        path: item.href,
        exact: false
      });

      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          key={item.href}
          label={item.label}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items,
            permission: permission
          })}
        </NavItem>
      );
    } else {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          key={item.href}
          label={item.label}
          title={item.title}
        />
      );
    }
  }

  return acc;
}

function NavBar({ openMobile, onMobileClose, dataUser, className, ...rest }) {
  //!Const
  const userInfor = useSelector(state => state.userReducer.userInfor);

  const classes = useStyles();
  const location = useLocation();
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  // const data = useSelector(state => state.data);
  //!State
  const [status, setStatus] = useState('online');
  const [openPopup, setopenPopup] = useState(false);
  const [data, setdata] = useState({});
  const [openMenu, setOpenMenu] = useState(false);
  const handleStatusToggle = () => {
    const statusSeq = {
      online: 'away',
      away: 'busy',
      busy: 'offline',
      offline: 'online'
    };

    setStatus(prevStatus => statusSeq[prevStatus]);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    setdata(dataUser);
  }, [dataUser]);

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <nav className={classes.navigation}>
        {navConfig.map(list =>
          renderNavItems({
            items: list.items,
            subheader: list.subheader,
            pathname: location.pathname,
            key: list.subheader,
            permission: userInfor.roleId && userInfor.roleId.permission
          })
        )}
      </nav>
      <Divider className={classes.divider} />
      <div
        ref={anchorRef}
        className={classes.profile}
        onClick={() => {
          setOpenMenu(!openMenu);
        }}
      >
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          classes={{
            dot: classes.badgeDot,
            badge: clsx({
              [classes.badge]: true,
              [classes.onlineBadge]: status === 'online',
              [classes.awayBadge]: status === 'away',
              [classes.busyBadge]: status === 'busy',
              [classes.offlineBadge]: status === 'offline'
            })
          }}
          variant="dot"
        >
          <Avatar
            alt="Person"
            // onClick={handleStatusToggle}
            className={classes.avatar}
            src={'https://st.quantrimang.com/photos/image/072015/22/avatar.jpg'}
          />
        </Badge>
        <div className={classes.details}>
          <Link
            component={RouterLink}
            // to="/profile/1/timeline"
            variant="h5"
            color="textPrimary"
            underline="none"
          >
            {data.email && data.email.split('@')[0]}
          </Link>
          {/* <Typography variant="body2">{data.user.bio}</Typography> */}
          <Menu
            anchorEl={anchorRef.current}
            className={classes.menu}
            onClose={() => {
              setOpenMenu(false);
            }}
            open={openMenu}
            elevation={1}
            // eslint-disable-next-line react/jsx-sort-props
            PaperProps={{
              style: {
                width: 250
                // bottom: '70px'
                // paddingBottom: '0px',
              }
            }}
          >
            <MenuItem
              component={RouterLink}
              to={`/setting/user/${localStorage.getItem('id')}/infor`}
              // onClick={() => {
              //   history.push(
              //     `/setting/user/${localStorage.getItem('id')}/infor`
              //   );
              // }}
            >
              <PersonIcon
                style={{
                  paddingBottom: '6px',
                  fontSize: '35px'
                }}
              />
              <span
                style={{
                  fontWeight: 500,
                  lineHeight: 1.75,
                  letterSpacing: '0.02857em',
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  textTransform: 'uppercase',
                  padding: '6px 16px'
                }}
              >
                Thiết lập tài khoản
              </span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(
                  userActions.logout({
                    refreshToken: localStorage.getItem('refreshtoken')
                  })
                );
                history.push('/auth/login');
              }}
            >
              <ExitToAppIcon
                style={{
                  paddingBottom: '6px',
                  fontSize: '35px'
                }}
              />
              <span
                style={{
                  fontWeight: 500,
                  lineHeight: 1.75,
                  letterSpacing: '0.02857em',
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  textTransform: 'uppercase',
                  padding: '6px 16px'
                }}
              >
                Đăng xuất
              </span>
            </MenuItem>
          </Menu>
        </div>
        <IconButton
          className={classes.moreButton}
          size="small"
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
        >
          <MoreIcon />
        </IconButton>
      </div>
    </div>
  );

  return (
    <>
      <Popup openPopup={openPopup} title="Thay đổi mật khẩu" maxWidth="sm">
        <ChangePassword setopenPopup={setopenPopup} />
      </Popup>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
