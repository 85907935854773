import TypeActions from '../TypeActions';

export const getListProvince = (params, callback) => {
  return {
    type: TypeActions.GET_LIST_PROVINCE_REQUEST,
    params,
    callback
  };
};
export const getListDistrict = (params, callback) => {
  return {
    type: TypeActions.GET_LIST_DISTRICT_REQUEST,
    params,
    callback
  };
};
export const getListWard = (params, callback) => {
  return {
    type: TypeActions.GET_LIST_WARD_REQUEST,
    params,
    callback
  };
};

export default {
  getListProvince,
  getListDistrict,
  getListWard
};
